/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from 'vue'
import { LMap, LTileLayer, LMarker, LControlLayers, LPolyline, LPolygon, LCircle, LCircleMarker, LIcon, LGeoJson, LPopup, LTooltip} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import VueTelInput from 'vue-tel-input';
import {BootstrapVue,IconsPlugin} from 'bootstrap-vue'
import 'vue-tel-input/dist/vue-tel-input.css';
import Register from './components/Register.vue';
import search_companies  from './components/search_companies.vue';
import maplocations from './components/mapLocations.vue';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

window.Vue = require('vue');

window.onload = function () {
    const app = new Vue({
        el: '#vueElem',
        components: {        
            LMap,
            LTileLayer,
            LMarker,
            LControlLayers,
            LPolyline,
            LPolygon,
            LCircle,
            LCircleMarker,
            LIcon,
            LGeoJson,
            LPopup,
            LTooltip,
            VueTelInput,
            Register,
            search_companies,
            maplocations}
    });
}