<template>
    <div>
    <form @submit.prevent="submitForm">
          <div class="containerSearch">
              <table class="table searchTable">
                <tr>
                  <td>
                    Company name*
                  </td>
                  <th>
                  <input 
                    v-model="name"
                    type="text"
                    id="searchName"
                    class="form-control searchField"
                  />
                  </th>
                </tr>
                <tr v-if="newEntry == true">
                  <td>
                    Chamber of commerce
                  </td>
                  <th>
                  <input 
                    v-model="CompKvk"
                    type="text"
                    id="searchKvk"
                    class="form-control searchField"
                  />
                  </th>
                </tr>
                <tr v-if="newEntry == true">
                  <td>
                    VAT number
                  </td>
                  <th>
                  <input 
                    v-model="CompBTW"
                    type="text"
                    id="searchBTW"
                    class="form-control searchField"
                  />
                  </th>
                </tr>
                <tr v-if="newEntry == true">
                  <td>
                    Phone number
                  </td>
                  <th>
                  <input 
                    v-model="CompPhMain"
                    type="text"
                    id="searchPhone"
                    class="form-control searchField"
                  />
                  </th>
                </tr>
                <tr v-if="newEntry == true">
                  <td>
                    Website
                  </td>
                  <th>
                  <input 
                    v-model="CompWeb"
                    type="text"
                    id="searchWeb"
                    class="form-control searchField"
                  />
                  </th>
                </tr>
                <tr v-if="newEntry == true">
                  <td>
                    Nation*
                  </td>
                  <th>
                    <select name="CompNation" class="form-control" v-model="CompNation" required>
                    <option v-for="(item, key, index) in countriesTimezonesSorted" :key="index" :value="item.country_code">
                      {{item.name}}
                    </option>
                    </select>
                  </th>
                </tr>
                <tr v-if="newEntry == true && CompNation == 'US'">
                  <td>
                    State*
                  </td>
                  <th>
                    <select v-model="CompState" name="CompState" class="form-control" required>
                      <option v-for="(item, key, index) in usStates" :key="index" :value="item.abbreviation">
                        {{item.name}}
                      </option>
                    </select>
                  </th>
                </tr>
                
                <tr>
                  <td>
                  </td>
                  <th>
                    <button class="btn searchField btn-info" type="submit" v-if="newEntry == false">Search <div v-if="loading" id="loading"></div></button>
                    <button class="btn searchField btn-info" type="submit" v-if="newEntry == true">Save company and generate ID <div v-if="loading" id="loading"></div></button>
                    <button class="btn searchField" v-if="formSubmitted || newEntry == true" type="button" id="resetField" @click="reset" >Clear</button>
                    <button class="btn searchField btn-yellow" type="button" id="createNew" v-if="newEntry == false" @click="newEntrySetup" style="float:right;">Create new External ID</button>
                  </th>
                </tr>
                <tr v-if="selectedValue">
                  <td colspan="4" class="copied">
                    The requested external id: <br/>
                    <b>{{selectedValue}}</b> <br/>
                    is copied to clipboard
                  </td>  
                </tr>
                <tr v-if="limited">
                  <td colspan="4" class="limitedErr">
                    Search is limited to 50 companies, please specify name further
                  </td>  
                </tr>
            </table>
          </div>
      </form>

      <input type="text" value="" id="clipboard" style="position: absolute; left: -9999px; top: -9999px;"/>

      <b-table v-if="formSubmitted" class="queuetable-cargokiosk queuetable-monitor queuetable-archive borderless searchresults" id="queue" show-empty :items="searchResults" :fields="fields" @row-clicked="onRowClicked" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :hover="hover"  :filter="filter" :filter-included-fields="filterOn">
          <!--
        <template slot="top-row" slot-scope="{ fields }">
              <td colspan="6" class="table-search">
              <b-form-input id="filter-input" v-model="filter" type="search" v-bind:placeholder="typeToSearch()" > </b-form-input>
              <b-button  @click="filter = '';sortBy = 'start';sortDesc = true" id="clearFilters" style="display:none;"></b-button>
              </td>
          </template>
          -->
        <template v-slot:cell(CompID)="data">
          <span class="badge badge-info-fs" style="font-size: 110%;color: #4b4b4b;">{{ data.value }}</span>
        </template>
        <template v-slot:cell(CompNation)="data">
          <span v-html="countryState(data.item)"></span>
        </template>
        <template v-slot:cell(CompName)="data">
          <span>{{ data.value.toUpperCase() }}</span>
        </template>
        <template v-slot:empty="scope">
          <h4><center>No results</center></h4>
        </template>
        <template #emptyfiltered="scope">
          <div style="text-align:center;">No results</div>
        </template>
      </b-table>
      </div>
  </template>
  <script>
  import countriesTimezones from "./data/countriesTimezones.json";
  import usStates from "./data/usStates.json";
  //import {VueTelInput} from 'vue-tel-input';

    export default {
      data() {
        return {
          name: "",
          CompKvk: "",
          CompBTW: "",
          CompPhMain: "",
          CompWeb: "",
          CompNation: "",
          CompState: "",
          searchResults: [],
          formSubmitted: false,
          loading: false,
          limited: false,
          newEntry: false,
          selectedValue: "",
          fields: [
          {key: 'CompID',label: 'External ID', sortable: true},
          {key: 'CompNation',label: 'Nation', sortable: true},
          {key: 'CompName',label: 'Name', sortable: true}
            ],
          hover: true,
          sortBy: 'CompName',
          sortDesc: true,
          filter: null,
          filterOn: ['CompName'],
          countriesTimezones: countriesTimezones,
          usStates: usStates,
        };
      },
      computed: {
        countriesTimezonesSorted: function() {
          return this.countriesTimezones.sort((t1,t2) => t1.name < t2.name ? -1 : 1);
        },
      },
      methods: {
        submitForm: function () {
          this.formSubmitted = false;
          this.limited = false;
          this.loading = true;
          this.selectedValue = "";
          var self = this;
          var searchJob = {newEntry: this.newEntry, name:this.name, CompKvk: this.CompKvk, CompBTW: this.CompBTW, CompPhMain: this.CompPhMain, CompWeb: this.CompWeb, CompNation: this.CompNation, CompState: this.CompState};
          if(this.name == "") {
            this.loading = false;
            this.formSubmitted = false;
            return;
          };
          $.post('t110/f002', {
            _token: $('meta[name=csrf-token]').attr('content'),
            searchJob: searchJob,
          })
          .fail(function(data) {
              console.log('error in search query');
              self.searchResults = {};
              self.formSubmitted = false;
              self.loading = false;
              self.limited = false;
          })
          .done(function(data) {
              if(self.newEntry) {
                self.formSubmitted = true;
                self.loading = false;
                $('#clipboard:text').val(data + ' - ' + self.name.toUpperCase());
                var copyText = $('#clipboard')[0];
                copyText.select();
                copyText.setSelectionRange(0, 99999); /*For mobile devices*/
                document.execCommand("copy");
                self.selectedValue = data + ' - ' + self.name.toUpperCase();
                self.reset();

              } else {
                self.searchResults = data;
                self.formSubmitted = true;
                self.loading = false;
                if(data && data.length == 50) {
                  self.limited = true;
                } else {
                  self.limited = false;
                }
              }
          })
        },
        reset() {
          this.searchResults = {};
          this.formSubmitted = false;
          this.limited = false;
          this.loading = false;
          this.newEntry = false;
          this.name = '';
          this.CompKvk = '';
          this.CompBTW = '';
          this.CompPhMain = '';
          this.CompWeb = '';
          this.CompNation = '';
          this.CompState = '';
        },
        newEntrySetup() {
          this.newEntry = true;
          this.selectedValue = "";
          this.formSubmitted = false;
        },
        countryState(item) {
          if(item.CompNation == 'US') {
            return item.CompNation.toUpperCase() + ' <small>' + item.CompState.toUpperCase() + '</small>';
          } else {
            return item.CompNation.toUpperCase();
          };
        },
        onRowClicked (item, index, event) {
          $('#clipboard:text').val(item.CompID + ' - ' + item.CompName.toUpperCase());
          var copyText = $('#clipboard')[0];
          copyText.select();
          copyText.setSelectionRange(0, 99999); /*For mobile devices*/
          document.execCommand("copy");
          this.reset();
          this.selectedValue = item.CompID + ' - ' + item.CompName.toUpperCase();
        },
        noQueue () {
          return 'No results';
        },
        filteredOut () {
          return 'No results';
        },
        typeToSearch () {
          return 'Type to search';
        },
      },
    };
  </script>
  <style>
  .inputname {
    text-align:left;
  }
  .searchTable {
    border-collapse: separate;
    border-spacing: 20px;
    margin-bottom:0px;
  }
  .containerSearch {
    text-align:left;
    margin-right:auto;
  }
  .queuetable-archive > thead > tr > th,   .queuetable-archive> thead > tr > td{
    border: 0 !important;
    font-family: 'Work Sans' !important;
    font-weight: 500 !important;
    padding: 2rem !important;
    vertical-align: middle !important;
}
  .queuetable-archive > tbody > tr > th,   .queuetable-archive > tbody > tr > td,  .queuetable-archive > tfoot > tr > th,  .queuetable-archive > tfoot > tr > td {
    border: 0 !important;
    font-family: 'Work Sans' !important;
    font-weight: 300 !important;
    padding: 2rem !important;
    vertical-align:middle !important;
}
  .queuetable-archive {
    margin:0px;
  }
  .queuetable-archive th {
    cursor: pointer;
  }
  .queuetable-archive > thead > tr > th:hover {
    background-color: #f5f5f5;
  }
  #loading {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid #dbdbdb;
    border-radius: 50%;
    border-top-color: #000;
    margin-bottom:-3px;
    margin-left: 5px;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }
  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  .limitedErr {
    text-align: center;
    font-style: italic;
    background-color: #474747;
    color: #fff;
    border-radius: 4px;
    padding: 5px;
  }
  .copied {
    text-align: center;
    font-style: italic;
    background-color: #ffd832;
    color: #252525;
    border-radius: 4px;
    padding: 5px;
  }
  .scontainer{
    overflow: hidden;
    background-color: #eee;
    text-align: center;
    cursor: pointer;
    margin:2px;
    padding: 0.5rem;
    font-family: 'Work Sans';
    font-weight: 400 !important;
    font-size: 1.2rem;
}

.queuetable-cargokiosk tbody {
    cursor: pointer;
}
  </style>